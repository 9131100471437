@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.tabs {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 10px;
	button {
		min-width: 100px;
		margin: 0 10px 10px;
		text-transform: capitalize;

		@include tablet() {
			min-width: 100px;
			margin: 0 10px 20px;
		}
	}
}

.recipeWrapper {
	grid-column: 1 / span 6;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	position: relative;
	transition: left 0.4s;

	> * {
		flex-basis: calc(100% / 2 - 10px);
	}

	@include tablet() {
		grid-column: 1 / span 12;

		> * {
			flex-basis: calc(100% / 3 - 15px);
		}
	}

	@include desktop() {
		grid-column: 2 / span 10;
	}
}
