@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.recipeBoxBenefits {
	display: flex;
	column-gap: 5px;
	row-gap: 5px;
	margin-bottom: 10px;
	max-width: 100%;
	flex-wrap: wrap;
	font-weight: 600;

	& .recipeBoxBenefit {
		background-color: $light-grey;
		color: $deep-green;
		font-size: 1.4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 28px;
		padding: 0 10px;
		line-height: 28px;

		@include tablet() {
			min-height: 32px;
			line-height: 32px;
		}
	}
}
