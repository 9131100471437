@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.heading {
	grid-column: 1 / span 6;

	@include tablet() {
		grid-column: 3 / span 8;
	}

	margin-bottom: 30px;
	text-align: center;
}

.purpleBackground {
	background: $purple;
	color: $white;

	& .heading {
		color: $white;
	}

	& .table .tableRow {
		&:nth-child(odd) {
			background-color: #4f194a;
		}
		&:nth-child(even) {
			background-color: #672462;
		}
	}
}

.altTick {
	color: $yellow;
}

.tableHeading {
	font-weight: 600;
}

.greenBackground {
	background: $light-green;
	color: $deep-green;

	& .heading {
		color: $deep-green;
	}

	& .table .tableRow {
		&:nth-child(odd) {
			background-color: #78823f;
		}
		&:nth-child(even) {
			background-color: #909b57;
		}
	}
}

.tableWrapper {
	& .tableContainer {
		width: calc(100% - 40px);
		display: flex;
		flex-direction: column;
		overflow: auto;
		margin: 0 20px;

		@include tablet() {
			overflow: visible;
		}

		& .table {
			@include tablet() {
				align-self: center;
			}
		}
	}
}

.deepGreenBackground {
	background: $deep-green;
	color: $white;

	& .heading {
		color: $white;
	}

	& .table .tableRow {
		&:nth-child(odd) {
			background-color: #1f413b;
		}
		&:nth-child(even) {
			background-color: $deep-green;
		}
	}
}

.largeText {
	font-weight: 900;
	font-size: 1.6rem;

	@include tablet() {
		font-size: 2.2rem;
	}

	@include tabletLarge() {
		font-size: 2.6rem;
	}
}

// SIDEICONS
.sideIcons-right-1,
.sideIcons-right-2,
.sideIcons-right-3,
.sideIcons-left-1,
.sideIcons-left-2,
.sideIcons-left-3 {
	position: relative;

	@include desktop() {
		&:before {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 100%;
			width: 400px;
			background-size: auto 115%;
			background-position: top center;
			background-repeat: no-repeat;
			top: 0;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:before {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-right-1:before {
	background-image: url("/images/side-icons-right-1.png");
	left: calc(100% - 12vw);
	@include desktopLarge() {
		left: auto;
		right: -170px;
	}
}

.sideIcons-right-2:before {
	background-image: url("/images/side-icons-right-2.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-right-3:before {
	background-image: url("/images/side-icons-right-3.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-left-1:before {
	background-image: url("/images/side-icons-left-1.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -180px;
	}
}

.sideIcons-left-2:before {
	background-image: url("/images/side-icons-left-2.png");
	right: calc(100% - 20vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-left-3:before {
	background-image: url("/images/side-icons-left-3.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.bottomZigZag {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		height: 25px;
		top: calc(100% - 4px);
		width: 100%;
		background-repeat: repeat;
		background-size: 25px 30px;
		z-index: 2;
		transform: rotate(180deg);
		background-image: url("/images/zig-zag-i.svg");

		@include tablet() {
			width: 200%;
			right: 0;
		}
	}

	&.deepGreenBackground {
		&::after {
			background-image: url("/images/zig-zag-deep-green-i.svg");
		}
	}
	&.purpleBackground {
		&::after {
			background-image: url("/images/zig-zag-purple-i.svg");
		}
	}
	&.greenBackground {
		&::after {
			background-image: url("/images/zig-zag-green-i.svg");
		}
	}
}

.cta {
	padding: 30px 0 10px;
}

.table {
	flex-direction: column;
	justify-content: center;
	font-size: 1.6rem;
	position: relative;
	display: table;
	width: 100%;

	.tableRow {
		display: table-row;
		flex-direction: row;

		&:nth-child(odd) {
			background-color: #bbb7b0;
		}

		&:nth-child(even) {
			background-color: #d2cec6;
		}

		&.splitCells {
			.tableCell {
				width: 50%;
			}
		}
	}

	.tableCell {
		flex: 1;
		display: table-cell;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;
		vertical-align: middle;
		padding: ($gutter / 2) * 1.5 10px;
		line-height: 1.8rem;
		min-height: 68px;

		&.largeText {
			color: $white;
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 1.8rem;

			@include tablet {
				font-size: 2.6rem;
				line-height: 2.8rem;
			}
		}

		&.yellow {
			color: $yellow;
		}

		&.white {
			color: $white;
		}

		&.yellowLarge {
			& .largeContent {
				color: $yellow;
			}
		}

		&.whiteLarge {
			& .largeContent {
				color: $white;
			}
		}

		@include tablet() {
			padding: ($gutter / 2) * 1.5;
			line-height: 2.2rem;
		}

		@include tabletLarge() {
			padding: $gutter;
			line-height: 2.6rem;
		}

		img {
			max-width: 60px;
			max-height: 60px;

			@include tablet() {
				max-height: 70px;
				max-width: 70px;
			}
		}
	}

	&.alignToSides {
		.tableCell {
			&:nth-child(odd) {
				text-align: left;
				align-items: flex-start;
			}

			&:nth-child(even) {
				text-align: right;
				align-items: flex-end;
			}
		}
	}
}

.largeContent {
	font-size: 1.8rem;
	line-height: 2rem;

	@include tablet() {
		font-size: 2rem;
		line-height: 2.2rem;
	}

	font-weight: 600;
}

.tableLogo {
	display: flex;
	justify-content: center;
}
