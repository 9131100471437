$color-yellow: #fcaf17;
$color-yellow-lighter: #f9b000;
$color-white: #ffffff;
$color-black: #45464b;
$color-dark-grey: #45464b;
$color-darker-grey: #737478;
$color-grey: #cccccc;
$color-light-grey: #f3f3f3;
$color-red: #fa4616;
$color-green: #93cb79;
$color-blue: #00a5af;
$color-dark-blue: #00a0b4;
$color-brown: #45251c;
$color-cream: #ffe9c1;
$color-purple: #808ad7;
$color-pink: #ff5372;
$color-teal: #00c3a5;
