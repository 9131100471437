@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.tag-wrapper {
	position: absolute;
	top: 20px;
	right: 0;
	z-index: 2;
	display: flex;
	filter: drop-shadow(-2px 3px 1px rgba(0, 0, 0, 0.15));
	max-width: 70%;
	justify-content: flex-end;
}

.tag-wrapper-in-modal {
	top: 80px;
}

.tag {
	min-height: 50px;
	background: $yellow;
	padding: 15px 15px 15px 10px;
	@include font-size(14);
	z-index: 2;
	color: $color-lightest;
	font-weight: $bold-base;
	line-height: 18px;
}

.triangle {
	position: absolute;
	right: 100%;
	height: 100%;
	width: 25px;
	overflow: hidden;
	top: 0;
	bottom: 0;
	z-index: 1;
	transform: translateX(1px);
	transform-origin: top right;
}

// Browsers have a hard time rendering borders properly when you're using them for layout.
// This is a fix for a gap that appears between the top and bottom at certain zoom levels.
.triangle {
	&::after {
		height: 51%;
		width: 100%;
		background-color: #f9b000;
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		right: 0;
		transform-origin: top right;
		transform: skew(36deg);
	}
	&::before {
		height: 51%;
		width: 100%;
		background-color: #f9b000;
		position: absolute;
		content: "";
		top: 50%;
		left: 0;
		right: 0;
		transform-origin: bottom left;
		transform: skew(-36deg);
	}
}
