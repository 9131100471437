$deep-green: #274c46;
$faded-deep-green: #bcc4c2;
$light-green: #96a34f;
$red: #be0021;
$faded-red: #dc7b8c;
$active-red: #c72f49;
$white: #ffffff;
$yellow: #f2a900;
$active-yellow: #f1b62f;
$faded-yellow: #efca76;
$brown: #553527;
$pink: #ffb4a8;
$grey: #737478;
$purple: #713170;
$softPurple: #ae8eae;
$orange: #e65a1e;
$faded-orange: #f7a582;
$off-white: #eae5dc;
$off-white-light: #f5f2ee;
$faded-off-white: #f5f2ed;
$light-grey: #cccccc;
$lighter-grey: #F5F2ED;
$medium-grey: #8a9c99;
