@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: $white;
	gap: 10px;

	&.stickyButtons {
		position: sticky;
		bottom: -1px;
		left: 0;
		right: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 14.8%);
		padding: 30px 20px 20px;
		z-index: 4;
	}

	&.transparent {
		background: transparent;
	}

	&.dualButton {
		flex-direction: column-reverse;
	}

	@include tablet() {
		&.dualButton {
			flex-direction: row;
		}
	}

	&.closeOnBottom {
		flex-direction: column;

		@include tablet() {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

		& > button {
			&:nth-child(1) {
				order: 2;

				@include tablet() {
					order: 1;
				}
			}
			&:nth-child(2) {
				order: 1;

				@include tablet() {
					order: 2;
				}
			}
			&:nth-child(3) {
				order: 3;
			}
			@include tablet() {
				flex-basis: calc(50% - 5px);
			}
		}
	}

	&.singleButton {
		justify-content: center;
	}
}
