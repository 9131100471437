/**
 *  Colours
 */

@import "./old-brand";
@import "./new-brand";
/** Actual colour variables to use */

$color-primary: $yellow;
$color-secondary: $color-blue;
$color-tertiary: $color-brown;
$color-light-brand: $color-cream;
$color-lightest: $color-white;
$color-nearly-lightest: $off-white;
$color-darkest: $color-black;
$color-dark: $color-dark-grey;
$color-darker: $color-darker-grey;
$color-light: $color-light-grey;
$color-mid: $color-grey;
$color-error: $color-red;
$color-success: $color-green;
$color-extra-brand: $color-purple;
$color-extra-brand-2: $color-teal;
$color-extra-brand-3: $color-pink;
$color-extra-brand-4: $color-dark-blue;
$color-extra-brand-5: $color-yellow-lighter;
/**
 * Typography
 */

/***** NEW BRAND FONTS *****/
/* RUBIK Web Bold */
@font-face {
	font-family: "rubik";
	src:
		url("/fonts/rubik-v19-latin-700.woff2") format("woff2"),
		url("/fonts/rubik-v19-latin-700.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
/* Sofia-pro regular - latin */
@font-face {
	font-family: "Sofia-pro";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	ascent-override: 90%;
	src:
		local(""),
		url("/fonts/sofiaPro-Regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/sofiaPro-Regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
	font-family: "Sofia-pro";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	ascent-override: 90%;
	src:
		local(""),
		url("/fonts/sofiaPro-Medium.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/sofiaPro-Medium.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
	font-family: "Sofia-pro";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	ascent-override: 90%;
	src:
		local(""),
		url("/fonts/sofiaPro-SemiBold.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("/fonts/sofiaPro-SemiBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
	font-family: "Sofia-pro";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	ascent-override: 90%;
	src:
		local(""),
		url("/fonts/SofiaPro-Bold.woff") format("woff"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/SofiaPro-Bold.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$headline-font-family: "rubik", "Helvetica", Arial, roboto, sans-serif;
$base-font-family: "Sofia-pro", Arial, roboto, sans-serif;
$base-font-size: 16;
$base-line-height: 1.6;
$sp-unit: ($base-font-size * $base-line-height) * 1px;

/**
 * Components
 */

$shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
$hover-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
$border-radius: 300px;
$xxs: 350px;
$xs: 421px;
$sm: 601px;
$md: 768px;
$lg: 1024px;
$xl: 1600px;
$container-max: 1200px;
$gutter: 20px;

$max-col-width: 76px;
$mobile-gutter: 10px;
$tablet-gutter: 20px;
$desktop-gutter: 22px;

$z-index-above-all: 9999;
$z-index-above-all-backdrop: 9998;
$z-index-below-modals: 9997;

$normal-base: 400;
$bold-base: 600;
$bold-heading: 700;
